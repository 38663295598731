import React, { useContext, useState, useEffect } from 'react';
import UserContext from '../../contexts/userContext';
import {
    Box,
    Paper,
    TextField
} from "@mui/material";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { Button } from "@mui/material";
import axios from 'axios';
import { useHistory} from 'react-router-dom';

function Form () {
    const {openPanel, setOpenPanel} = useContext(UserContext);
    const {selectValue, setSelectValue} = useContext(UserContext);
    const {refreshData, setRefreshData} = useContext(UserContext);
    const [formData, setFormData] = useState({});
    const [disableForm, setDisableForm] = useState(false);
    const [formForAdd, setFormForAdd] = useState(false);
    const history = useHistory();

    useEffect(() => {
        console.log(selectValue === undefined);
        if(selectValue !== undefined) {
            if(JSON.stringify(selectValue) === "{}") {
                setFormData({});
                setDisableForm(false);
                setFormForAdd(true);
            }
            else {
                setFormData(selectValue);
                setDisableForm(true);
                setFormForAdd(false);
            }
        }        
    }, [selectValue]);

    const saveFormData = async () => {
        console.log(formData);
        let token = localStorage.getItem("auth-token");
        let url = "";
        if(formForAdd) {
            url = "addData";
        }
        else {
            url = "updateData"
        }
        try {
          let response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/data/${url}`, formData, {
            headers: { "x-auth-token": token },
          });
          console.log(response);
          setRefreshData(true);
          setOpenPanel(false);
        }
        catch(err) {
          if(err.response.data.error === 'jwt expired') {
            alert("You are logged out");
            localStorage.setItem("auth-token", "");
            history.push('/login');
          }
        }
      };

      const deleteInfo = async () => {
        let token = localStorage.getItem("auth-token");
        try {
          let response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/data/deleteData`, formData, {
            headers: { "x-auth-token": token },
          });
          setRefreshData(true);
          setOpenPanel(false);
          console.log(response);
        }
        catch(err) {
          if(err.response.data.error === 'jwt expired') {
            alert("You are logged out");
            localStorage.setItem("auth-token", "");
            history.push('/login');
          }
        }
      };

    const calculateYearExperience = (exp) => {
        if(exp === undefined) {
            return 0;
        }
        let experience = parseFloat(exp['$numberDecimal']);
        const year = Math.floor(experience);
        return year;
    }

    const calculateMonthExperience = (exp) => {
        if(exp === undefined) {
            return 0;
        }
        let experience = parseFloat(exp['$numberDecimal']);
        const month = (experience - Math.floor(experience)) * 12;
        return Math.round(month);
    }
    
    const calculateSalary = (sal) => {
        if(sal === undefined) {
            return 0;
        }
        let salary = parseFloat(sal['$numberDecimal']);
        return salary;
    }

    const setFormField = (key, value) => {
        setFormData({
            ...formData,
            [key]: value
        });
    }

    return (
        <SlidingPane
            className="some-custom-class"
            overlayClassName="some-custom-overlay-class"
            isOpen={openPanel}
            title={formForAdd ? 'Add new information' : selectValue.name + ' Information'}
            subtitle="Please have a look"
            onRequestClose={() => {
                setOpenPanel(false);
                setSelectValue({});
            }}
            width='45%'>
                {formForAdd ? (
                    <>Add new value</>
                ) : 
                (
                    <>
                        <Button onClick={() => {
                        setDisableForm(false);
                        }}>Edit</Button>
                        <Button onClick={() => {
                            deleteInfo();
                            setOpenPanel(false);
                        }}>Delete</Button>
                    </>
                )}
                <Paper elevation={3}>
                    <TextField
                        id="name"
                        disabled={disableForm}
                        defaultValue={""}
                        value={formData?.name ?? ''}
                        label="Name"
                        onChange={(event) => {
                            setFormField('name', event.target.value);
                        }}
                    />
                </Paper>
                <Paper elevation={3}>
                    <TextField
                        id="emailId"
                        disabled={disableForm}
                        defaultValue={""}
                        value={formData.emailId}
                        label="Email Id"
                        onChange={(event) => {
                            setFormField('emailId', event.target.value);
                        }}
                    />
                </Paper>
                <Paper elevation={3}>
                    <TextField
                        id="phoneNumber"
                        disabled={disableForm}
                        defaultValue={""}
                        value={formData.phoneNumber}
                        label="Phone Number"
                        onChange={(event) => {
                            setFormField('phoneNumber', event.target.value);
                        }}
                    />
                </Paper>
                <Paper elevation={3}>
                    <TextField
                        id="alternamePhone"
                        disabled={disableForm}
                        defaultValue={""}
                        value={formData.alternatePhone}
                        label="Alternate Phone Number"
                        onChange={(event) => {
                            setFormField('alternatePhone', event.target.value);
                        }}
                    />
                </Paper>
                <Paper elevation={3}>
                    <TextField
                        id="currentLocation"
                        disabled={disableForm}
                        defaultValue={""}
                        value={formData.currentLocation}
                        label="Current Location"
                        onChange={(event) => {
                            setFormField('currentLocation', event.target.value);
                        }}
                    />
                </Paper>
                <Paper elevation={3}>
                    <TextField
                        id="preferredLocation"
                        disabled={disableForm}
                        defaultValue={""}
                        value={formData?.preferredLocation}
                        label="Preferred Location"
                        placeholder='Enter values with comma separated'
                        onChange={(event) => {
                            setFormField('preferredLocation', event.target.value);
                        }}
                    />
                </Paper>
                
                <Paper elevation={3}>
                    <TextField
                        id="totalExperienceinYear"
                        disabled={disableForm}
                        defaultValue={0}
                        value={calculateYearExperience(formData.totalExperience)}
                        label="Total Experience in year"
                        onChange={(event) => {
                            let value = event.target.value
                            let sal = {};
                            if(formData.totalExperience !== undefined && formData.totalExperience['$numberDecimal'] !== undefined) {
                                let experience = parseFloat(formData.totalExperience['$numberDecimal']);
                                value = value + experience - Math.floor(experience);
                            }
                            sal['$numberDecimal'] = value.toString();
                            setFormField('totalExperience', sal);
                        }}
                    />
                    <TextField
                        id="totalExperienceinMonth"
                        disabled={disableForm}
                        defaultValue={0}
                        value={calculateMonthExperience(formData.totalExperience)}
                        label="Total Experience in month"
                        onChange={(event) => {
                            let value = event.target.value;
                            let experience = 0;
                            let sal = {};
                            if(formData.totalExperience !== undefined && formData.totalExperience['$numberDecimal'] !== undefined) {
                                experience = parseFloat(formData.totalExperience['$numberDecimal']);
                            }
                            sal['$numberDecimal'] = (Math.floor(experience) + value/12).toString();
                            setFormField('totalExperience', sal);
                        }}
                    />
                </Paper>
                <Paper elevation={3}>
                    <TextField
                        id="annualSalary"
                        disabled={disableForm}
                        defaultValue={0}
                        value={formData.annualSalary === undefined ? 0 : formData.annualSalary['$numberDecimal']}
                        label="Annual Salary (in lakhs with decimal)"
                        onChange={(event) => {
                            let sal = {};
                            sal['$numberDecimal'] = event.target.value;
                            setFormField('annualSalary', sal);
                        }}
                    />
                </Paper>
                <Paper elevation={3}>
                    <TextField
                        id="currentCompany"
                        disabled={disableForm}
                        defaultValue={""}
                        value={formData.currentCompany}
                        label="Current Company"
                        onChange={(event) => {
                            setFormField('currentCompany', event.target.value);
                        }}
                    />
                </Paper>
                
                <Paper elevation={3}>
                    <TextField
                        id="currentDesignation"
                        disabled={disableForm}
                        defaultValue={""}
                        value={formData.currentDesignation}
                        label="Current Designation"
                        onChange={(event) => {
                            setFormField('currentDesignation', event.target.value);
                        }}
                    />
                </Paper>
                <Paper elevation={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            id="currentCompanyJoinDate"
                            label="Current Company Join Date"
                            value={formData.currentCompanyJoinDate !== undefined ? dayjs(formData.currentCompanyJoinDate) : null}
                            disabled = {disableForm}
                            onChange={(event) => {
                                setFormField('currentCompanyJoinDate', event["$d"]);
                            }}
                        />
                    </LocalizationProvider>
                </Paper>
                <Paper elevation={3}>
                    <TextField
                        id="previousEmployer"
                        disabled={disableForm}
                        defaultValue={""}
                        value={formData.previousEmployer}
                        label="Previous Employer"
                        onChange={(event) => {
                            setFormField('previousEmployer', event.target.value);
                        }}
                    />
                </Paper>
                <Paper elevation={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            id="dateOfBirth"
                            label="Date Of Birth"
                            value={formData.dateOfBirth === undefined ? null : dayjs(formData.dateOfBirth)}
                            disabled = {disableForm}
                            onChange={(event) => {
                                setFormField('dateOfBirth', event["$d"]);
                            }}
                        />
                    </LocalizationProvider>
                </Paper>
                <Paper elevation={3}>
                    <TextField
                        id="permanentAddress"
                        disabled={disableForm}
                        defaultValue={""}
                        value={formData.permanentAddress}
                        label="Permanent Address"
                        onChange={(event) => {
                            setFormField('permanentAddress', event.target.value);
                        }}
                    />
                </Paper>
                {formForAdd || !disableForm ? (
                    <>
                        <Button onClick={() => {
                            setDisableForm(true);
                            saveFormData();
                        }}>Save</Button>
                    </>
                ) : 
                (
                    <>
                    </>
                )}
        </SlidingPane>
    );
}
 
export default Form;