import './App.css';
import React, {useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import axios from 'axios';
import Home from './components/home/Home';
import Login from './components/login/login';
import UserContext from './contexts/userContext';

function App() {
  const [ userData, setUserData] = useState({
    token: undefined,
    user: undefined
  });

  useEffect(() => {
    const checkLoggedIn = async () => {
      let token = localStorage.getItem("auth-token");
      if(token === null){
        localStorage.setItem("auth-token", "");
        token = "";
        setUserData({
          token,
          undefined
        });
        return;
      }
      try {
        const tokenResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/tokenIsValid`, null, {headers: {"x-auth-token": token}});
        if (tokenResponse.data) {
          const userRes = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth`, {
            headers: { "x-auth-token": token },
          });
          setUserData({
            token,
            user: userRes.data,
          });
        }
      }
      catch (err) {
        localStorage.setItem("auth-token", "");
        setUserData({
          undefined,
          undefined
        })
      }
    }

    checkLoggedIn();
  }, []);

  return (
    <BrowserRouter>
      <UserContext.Provider value={{ userData, setUserData }}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
        </Switch>
      </UserContext.Provider>
    </BrowserRouter>
  );
}

export default App;
