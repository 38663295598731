import React, { useContext, useState, useEffect } from 'react';
import UserContext from '../../contexts/userContext';
import {
    MenuItem,
    Slider,
    Box,
    Autocomplete,
    TextField,
    Button
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { createFilterOptions } from '@mui/material/Autocomplete';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import './Filters.css';

function Filters () {
    const {filter, setFilter} = useContext(UserContext);
    const {refreshData, setRefreshData} = useContext(UserContext);
    const basicFilter = {
        experience: [0, 10],
        salary: [0, 10],
        age: [20, 30],
        location: [],
        channel: [],
        search: ''
      };
    const [ filterData, setFilterData] = useState(basicFilter);
    const [location, setLocation] = useState([]);
    const [searchBox, setSearchBox] = useState("");
    const minDistance = 1;
    const history = useHistory();

    const handleCheckboxChange = (event, values) => {
        const {
          target: { value },
        } = event;
        setFilterData({...filterData, location: values});
    };

    const handleSliderChange = (event, newValue, activeThumb) => {
        const name = event.target.name;
        if (!Array.isArray(newValue) || !name) {
          return;
        }
        if (activeThumb === 0) {
          setFilterData({
            ...filterData, 
            [name]: [Math.min(newValue[0], filterData[name][1] - minDistance), filterData[name][1]]
          });
        } else {
          setFilterData({
            ...filterData,
            [name]: [filterData[name][0], Math.max(newValue[1], filterData[name][0] + minDistance)]
          });
        }
    };

    const fetchFilterData = async () => {
        let token = localStorage.getItem("auth-token");
        try {
          let response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/data/getHeaders`, {
            headers: { "x-auth-token": token },
          });
          setLocation(response.data);
        }
        catch(err) {
          if(err.response.data.error === 'jwt expired') {
            alert("You are logged out");
            localStorage.setItem("auth-token", "");
            history.push('/login');
          }
        }
    };

    useEffect(() => {
        if(location.length === 0) {
            fetchFilterData();
        }
        if(refreshData) {
            fetchFilterData();
        }
        setRefreshData(false);
    }, []);

    useEffect(() => {
    if(refreshData) {
        fetchFilterData();
    }
    setRefreshData(false);
    }, [refreshData]);

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 100,
    });

    return (
        <div>
            <div id="upperFilter">
                <Box sx={{ margin: 5, width: 300, border: 1, padding: '20px' }}>
                    Salary
                    <Slider
                        name = "salary"
                        getAriaLabel={() => 'Salary'}
                        value={filterData.salary}
                        step = {0.5}
                        max = {50}
                        onChange={handleSliderChange}
                        valueLabelDisplay="auto"
                        disableSwap
                    />
                    Min: {filterData.salary[0]}
                    Max: {filterData.salary[1]}
                </Box>
                <Box sx={{ margin: 5, width: 300, border: 1, padding: '20px' }}>
                    Age
                    <Slider
                        name = "age"
                        getAriaLabel={() => 'Age'}
                        value={filterData.age}
                        step = {0.5}
                        max = {50}
                        onChange={handleSliderChange}
                        valueLabelDisplay="auto"
                        disableSwap
                    />
                    Min: {filterData.age[0]}
                    Max: {filterData.age[1]}
                </Box>
                <Box sx={{ margin: 5, width: 300, border: 1, padding: '20px' }}>
                    Experience
                    <Slider
                        name = "experience"
                        getAriaLabel={() => 'Experience'}
                        value={filterData.experience}
                        step = {0.5}
                        max = {50}
                        onChange={handleSliderChange}
                        valueLabelDisplay="auto"
                        disableSwap
                    />
                    Min: {filterData.experience[0]}
                    Max: {filterData.experience[1]}
                </Box>
            </div>
            <div id="lowerFilters">
                <TextField
                    sx ={{width: 400}}
                    id="outlined-controlled"
                    label="Search any value here"
                    value={filterData.search}
                    onChange={(event) => {
                        setFilterData({
                            ...filterData,
                            search: event.target.value
                        });
                    }}
                />
                <Box sx ={{width: 400}}>
                    <Autocomplete
                        sx={{ m: 1, width: 500 }}
                        multiple
                        id="tags-standard"
                        options={location}
                        getOptionLabel={(option) => option}
                        value={filterData.location}
                        onChange={handleCheckboxChange}
                        filterOptions={filterOptions}
                        disableCloseOnSelect
                        inputValue={searchBox}
                        onInputChange={(event, value, reason) => {
                            if ( event && event.type === 'blur' ) {
                                setSearchBox('');
                            } else if ( reason !== 'reset' ) {
                                setSearchBox(value);
                            }
                        }}
                        renderOption={(props, option, { selected }) => (
                            <MenuItem
                            key={option}
                            value={option}
                            sx={{ justifyContent: "space-between" }}
                            {...props}
                            >
                            {option}
                            {selected ? <CheckIcon color="info" /> : null}
                            </MenuItem>
                        )}
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            variant="outlined"
                            label="Select Location"
                            placeholder="Location"
                            />
                        )}
                    />
                </Box>
            </div>
            <Button 
                onClick={() => {setFilter(basicFilter)}}
            >
              Reset
            </Button>
            <Button 
                onClick={() => {setFilter(filterData)}}
            >
              Submit
            </Button>
        </div>
    );
}
 
export default Filters;