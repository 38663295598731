import axios from "axios";
import { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import UserContext from "../../contexts/userContext";
import './Login.css';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState();
  const { userData, setUserData } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    let token = localStorage.getItem("auth-token");
    if(token && userData.user){
      history.push("/");
    }
  }, [userData]);

  const submit = async (e) => {
      e.preventDefault();
      try{
          const loginUser = {email, password};
          const loginResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/login`, loginUser);
          setUserData({
              token: loginResponse.data.token,
              user: loginResponse.data.user
          });
          localStorage.setItem("auth-token", loginResponse.data.token);
          history.push("/");
      } catch(err) {
          err.response.data.msg && setError(err.response.data.msg)
          alert(error);
      }      
  };

  
  return(
    <div id="root">
        <section>
            <div className="imgBx">
                <img src="https://cdn.yellowmessenger.com/FFd3E3nWJXzc1684681147460.jpg" alt="Bg img" />
            </div>
            <div className="contentBx">
                <div className="formBx">
                    <div className="twaLogo">
                        <img src="https://i.ibb.co/W6WDp3H/TWALogo.png" alt="TWA logo" />
                    </div>
                    <h2>Login</h2>
                    <form onSubmit={submit}>
                        <div className="inputBx">
                            <span>Username</span>
                            <input type="text" onChange={e => setEmail(e.target.value)} placeholder='Email or username' />
                        </div>
                        <div className="inputBx">
                            <span>Password</span>
                            <input type="password" onChange={e => setPassword(e.target.value)} placeholder='123@abc'/>
                        </div>
                        <div className="inputBx">
                            <input type="submit" value="Sign in" />
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </div>
);

}

export default Login;
