import React, { useEffect, useState, useContext } from "react";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import UserContext from '../../contexts/userContext';
import axios from 'axios';
import { useHistory} from 'react-router-dom';
import { Button } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#808080',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const headCells = [
  {
    id: "view",
    label: "",
    canSort: false
  },
  {
    id: "id",
    label: "Id",
    canSort: false
  },
  {
    id: "name",
    label: "Name",
    canSort: true
  },
  {
    id: "phoneNumber",
    label: "Phone",
    canSort: false
  },
  {
    id: "currentLocation",
    label: "Location",
    canSort: false
  },
  {
    id: "currentCompany",
    label: "Company",
    canSort: false
  },
  {
    id: "channel",
    label: "Channel",
    canSort: true
  },
  {
    id: "totalExperience",
    label: "Experience",
    canSort: true
  },
  {
    id: "annualSalary",
    label: "Salary",
    canSort: true
  }
];

export default function UserTableData() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('_id');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const {filter, setFilter} = useContext(UserContext);
  const {refreshData, setRefreshData} = useContext(UserContext);
  const {openPanel, setOpenPanel} = useContext(UserContext);
  const {selectValue, setSelectValue} = useContext(UserContext);
  const [employeeData, setEmployeeData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const history = useHistory();

  const fetchEmployeeData = async () => {
    setEmployeeData([]);
    let token = localStorage.getItem("auth-token");
    try {
      let data = {
        filters: filter,
        page: page,
        pageSize: rowsPerPage,
        order: orderBy,
        orderBy: order
      }
      let response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/data`, data, {
        headers: { "x-auth-token": token },
      });
      setEmployeeData(response.data);
    }
    catch(err) {
      if(err.response.data.error === 'jwt expired') {
        alert("You are logged out");
        localStorage.setItem("auth-token", "");
        history.push('/login');
      }
    }
  };

  const fetchTotalCount = async () => {
    setTotalCount(0);
    let token = localStorage.getItem("auth-token");
    try {
      let data = {
        filters: filter,
        page: page
      }
      let response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/data/count`, data, {
        headers: { "x-auth-token": token },
      });
      setTotalCount(response.data);
    }
    catch(err) {
      if(err.response.data.error === 'jwt expired') {
        alert("You are logged out");
        localStorage.setItem("auth-token", "");
        history.push('/login');
      }
    }
  };

  useEffect(() => {
    if(refreshData) {
      setPage(0);
      fetchEmployeeData();
      fetchTotalCount();
    }
    setRefreshData(false);
  }, [filter, refreshData]);
  
  useEffect(() => {
    fetchEmployeeData();
    if(totalCount === 0) {
      fetchTotalCount();
    }
  }, [page, rowsPerPage, orderBy, order]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createSortHandler = (property) => (event) => {
    if(orderBy === property) {
      setOrder(order === 'asc' ? 'desc': 'asc');
    }
    else {
      setOrder('asc');
    }
    setOrderBy(property);
  };

  const calculateExperience = (exp) => {
    let experience = parseFloat(exp['$numberDecimal']);
    const year = Math.floor(experience);
    const month = Math.ceil((experience - year)*12);
    let res = year + ' years ';
    if(month!==0) {
      res = res + month +' months';
    }
    return res;
  }

  const calculateSalary = (sal) => {
    let salary = parseFloat(sal['$numberDecimal']);
    let res = 'Rs. '+ salary + ' Lakhs';
    return res;
  }

  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
    <TableContainer component={Paper}>
      <Table sx={{ width: '100%' }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => {
              if(headCell.canSort) {
                return (
                  <StyledTableCell sortDirection={orderBy === headCell.id ? order : false}>
                  <TableSortLabel 
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}>
                    {headCell.label}
                  </TableSortLabel>
                </StyledTableCell>
                )
              }
              else {
                return (
                  <StyledTableCell>
                    {headCell.label}
                  </StyledTableCell>
                )
              }
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {employeeData.map((row, ind) => (
            <StyledTableRow key={row.phoneNumber}>
              <StyledTableCell scope="row">
                <Button onClick={() => {
                  setOpenPanel(true);
                  setSelectValue(row);
                }}>View</Button>
                
              </StyledTableCell>
              <StyledTableCell scope="row">
                {page*rowsPerPage + ind + 1}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.phoneNumber}</StyledTableCell>
              <StyledTableCell align="right">{row.currentLocation}</StyledTableCell>
              <StyledTableCell align="right">{row.currentCompany}</StyledTableCell>
              <StyledTableCell align="right">{row.channel}</StyledTableCell>
              <StyledTableCell align="right">{calculateExperience(row.totalExperience)}</StyledTableCell>
              <StyledTableCell align="right">{calculateSalary(row.annualSalary)}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
        rowsPerPageOptions={[50, 75, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
    />
    </Paper>
  );
}
