import React, { useEffect, useState, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import UserContext from '../../contexts/userContext';
import Filters from "../filters/FIlters";
import UserTableData from '../UserDataTable/UserTableData';
import { Button } from "@mui/material";

import './Home.css'
import Form from '../form/form';

function Home () {
    const {userData, setUserData} = useContext(UserContext);
    const [openPanel, setOpenPanel] = useState(false);
    const [refreshData, setRefreshData] = useState(false);
    const [selectValue, setSelectValue] = useState({});
    const history = useHistory();
    const basicFilter = {
        experience: [0, 10],
        salary: [0, 10],
        age: [20, 30],
        location: [],
        channel: [],
        search: ''
      };
    const [ filter, setFilter] = useState(basicFilter);
    useEffect(() => {
        if(!userData.user) {
            history.push("/login");
        }
    }, []);

    const logout = () => {
        localStorage.setItem("auth-token", ""); 
        setUserData({undefined, undefined});
    } 

    return (
        <div style={{margin: '50px'}}>
            {userData.user ? (
                <>                
                    <div id="header">
                        <h1 id="welcomeText">Welcome {userData.user.displayName}</h1>
                        <Button id="logout" onClick={logout} sx={{ color: 'red', background: 'thistle' }}>LogOut</Button>
                        <div class="clearfix"></div>
                    </div>
                    <UserContext.Provider value={{ filter, setFilter, openPanel, setOpenPanel, selectValue, setSelectValue, refreshData, setRefreshData }}>
                        <Button onClick={() => {
                            setSelectValue({});
                            setOpenPanel(true);
                        }} sx={{ font: 'icon' }}>Add New Value</Button>
                        <Filters />
                        {JSON.stringify(filter) !== JSON.stringify(basicFilter) ?
                                <UserTableData/>
                            : <div><h1>Select filters first </h1></div>
                        }
                        <Form/>
                    </UserContext.Provider>
                </>        
            ) : (
                <>
                    <h2>Thanks For visiting The Wall Associates</h2>
                    <Link to="/login">Login</Link>
                </>
            )}
        </div>
    );
}
 
export default Home;